import React, { useContext, memo } from 'react';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { DESCRICAO_PROCEDENCIA_PEDIDO, DESCRICAO_TIPO_PROCESSO, PERFIL, TIPO_OPERACAO, formatarSituacaoLabelCorProcesso, situacao } from '../../../common/Constants';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { Chip } from '@material-ui/core';
import { Util } from '@stt-utilitarios/core';
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttTranslateHook,
    SttDivider,
    SttLink,
    SttModal
} from '@stt-componentes/core';
import { recarregarPesquisa, valoresIniciais } from '../../../signals/util';
import { v4 as uuidv4 } from 'uuid';
import Functions from '../../../common/Functions';
import carregando from '../../../signals/carregando';
import alerta from '../../../signals/alerta';
import { getHeaders } from '../../../request';
import Operacoes from '../../operacao';
import { temPerfilRBAC } from '../../../security/acl';
import { usuario } from '../../../signals';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./custom-carousel.css";
import { Carousel } from 'react-responsive-carousel';
import AnexosProcesso from './anexo';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    divWrapperMovimentacao: {
        marginBottom: theme.spacing(1)
    },
    root: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1)
    },
    textoQuebraLinha: {
        wordBreak: 'break-all',
        whiteSpace: 'pre-line',
        textTransform: 'uppercase'
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: 'auto',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    },
    divider: {
        marginBottom: theme.spacing(1)
    },
    divCentral: {
        height: '100%'
    },
    modalCarousel: {
        minHeight: '80vh',
    },
    carousel: {
        '& .carousel.carousel-slider .control-arrow': {
            backgroundColor: 'rgba(0, 0, 0, 0.50)'
        }
    },
    buttonWrapper: {
        display: 'flex'
    },
}));

const Detalhes = ({ id, voltar, esconderBotoes }) => {
    useSignals();
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const APAC_API_BASE_URL = global.gConfig.url_base_apac;
    const navigate = useNavigate();
    const permissoesProcesso = useSignal({});
    const tipoOperacao = useSignal(null);
    const openModalOperacao = useSignal(false);
    const outrosDados = useSignal({});
    const exibirGaleriaAnexos = useSignal(false);
    const selectedItem = useSignal(0);
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    const processo = useSignal(null);

    useSignalEffect(() => {
        buscarSolicitacao(id);
    });

    const buscarSolicitacao = (id) => {
        const formatarDadosExibicao = (dados) => {
            if (dados.cpf_paciente) {
                dados.cpf_paciente = ('00000000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, "")).slice(-11);
            }

            if (dados.anexos?.length) {
                dados.anexos.forEach(anexo => {
                    anexo.idCodificado = window.btoa(anexo.id);
                });
            }
            if (dados.cep_paciente) {
                dados.cpf_paciente = ('00000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, "")).slice(-8);
            }
        }

        return axios.get(`${global.gConfig.url_base_apac}/solicitacao/${id}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { data } = response.data;
                    formatarDadosExibicao(data);
                    valoresIniciais.value = Functions.iniciarValoresSolicitacao(data);
                    processo.value = data;
                    permissoesProcesso.value = Functions.verificarPossibilidadesProcesso({ situacaoProcesso: parseInt(data.situacao), idFuncionarioPreenchedor: data.id_funcionario_preenchedor });
                }
            })
            .catch(err => console.log(err));
    }

    const confirmarSolicitarNumeroApac = () => {
        alerta.value = {
            open: true,
            title: strings.atencao,
            type: 'alert',
            message: strings.confirmacaoSolicitarNumeroApac,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        solicitarNumeroApac();

                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            ],
            onClose: () => {
                alerta.value = {
                    ...alerta.value,
                    open: false
                }
            }
        }
    }

    const solicitarNumeroApac = (imprimir) => {
        carregando.value = {
            open: true,
            text: strings.gerandoNumeroApac
        }
        axios
            .post(`${APAC_API_BASE_URL}/solicitacao/numero-apac`, { id: processo.value.id }, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    if (imprimir) {
                        buscarSolicitacao(response.data.data.id);
                        recarregarPesquisa.value = true;
                        imprimirProcesso(response.data.data.id);
                        return;
                    }

                    alerta.value = {
                        open: true,
                        title: strings.sucesso,
                        type: 'success',
                        message: response.data.message,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                    buscarSolicitacao(response.data.data.id);
                                    recarregarPesquisa.value = true;
                                }
                            }

                        ],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                            buscarSolicitacao(response.data.data.id);
                            recarregarPesquisa.value = true;
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let mensagem = strings.erroSolicitarNumeroApac;

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem) {
                            mensagem = arrMensagem.join('\n');
                        }
                    } else if (response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        const { data } = response;
                        mensagem = data.message ? data.message : strings.erroSolicitarNumeroApac;
                    }
                }
                alerta.value = {
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: mensagem,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }

                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            })
            .finally(() => {
                carregando.value = {
                    open: false,
                    text: ''
                }
            });
    }

    const imprimirProcesso = (idProcesso) => {
        const idCodificado = window.btoa(idProcesso);
        const params = {
            idCodificado,
            versao: global.gConfig.modulo_versao,
            modulo: global.gConfig.modulo_nome,
            basename: global.gConfig.basename ? global.gConfig.basename : global.gConfig.config_id,
        };

        carregando.value = {
            open: true,
            text: strings.imprimindoApac
        }

        axios
            .get(`${APAC_API_BASE_URL}/solicitacao/imprimir`, { params, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    window.open(response.data);
                }
            })
            .catch(err => {
                console.log(err);
                alerta.value = {
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: strings.erroImprimirProcesso,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            })
            .finally(() => {
                carregando.value = {
                    open: false,
                    text: ''
                }
            });
    }

    const abrirOperacao = (tipo) => {
        if (tipo === TIPO_OPERACAO.AUTORIZACAO) {
            const formData = new FormData();
            formData.append('id', processo.value.id);
            formData.append('outroProcedimento', processo.value.outro_procedimento);
            formData.append('codigoProcedimento', processo.value.procedimento.codigo);

            alerta.value = {
                open: true,
                title: strings.atencao,
                type: 'alert',
                message: strings.confirmarAutorizacao,
                options: [
                    {
                        title: strings.sim,
                        onClick: () => {
                            carregando.value = {
                                open: true,
                                text: ''
                            }
                            axios.post(`${APAC_API_BASE_URL}/operacao/autorizar`, formData, { headers: getHeaders() })
                                .then((response) => {
                                    const { data } = response;
                                    if (response.status === HttpStatus.OK) {
                                        alerta.value = {
                                            open: true,
                                            title: strings.sucesso,
                                            type: 'success',
                                            message: data.message ? data.message : strings.sucessoOperacao,
                                            options: [
                                                {
                                                    title: strings.ok,
                                                    onClick: () => {
                                                        alerta.value = {
                                                            ...alerta.value,
                                                            open: false
                                                        }
                                                        voltar(true);
                                                    }
                                                }

                                            ],
                                            onClose: () => {
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                }
                                                voltar(true);
                                            }
                                        }

                                    }
                                })
                                .catch(err => {
                                    const { response } = err;
                                    let mensagem = strings.mensagemErroGeral;

                                    if (response) {
                                        if (response.status === HttpStatus.BAD_REQUEST) {
                                            const { data } = response;
                                            let arrMensagem = [];
                                            data.errors.forEach(error => {
                                                arrMensagem.push(`- ${error.message}`);
                                            });
                                            if (arrMensagem) {
                                                mensagem = arrMensagem.join('\n');
                                            }
                                        }
                                    }
                                    alerta.value = {
                                        open: true,
                                        title: strings.erro,
                                        type: 'error',
                                        message: mensagem,
                                        options: [
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    alerta.value = {
                                                        ...alerta.value,
                                                        open: false
                                                    }
                                                }
                                            }

                                        ],
                                        onClose: () => {
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            }
                                        }
                                    }
                                })
                                .finally(() => {
                                    carregando.value = {
                                        open: false,
                                        text: ''
                                    }
                                });
                        }
                    },
                    {
                        title: strings.nao,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        },
                    }

                ],
                onClose: () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    }
                }
            }

            return;
        }

        if (tipo === TIPO_OPERACAO.ENCAMINHAR_AUTORIZADOR) {
            outrosDados.value = {
                ...outrosDados.value,
                idInstituicaoAutorizadora: processo.value.instituicao_autorizadora.id,
                funcionarioAutorizador: processo.value.autorizador
            };
        }

        if (tipo === TIPO_OPERACAO.DEVOLUCAO) {
            outrosDados.value = {
                procedimento: processo.value.procedimento,
                outroProcedimento: processo.value.outro_procedimento,
                situacaoProcesso: processo.value.situacao
            }
        }

        if (tipo === TIPO_OPERACAO.APROVACAO) {
            outrosDados.value = {
                dataValidade: processo.value.data_validade,
                dataFinalValidade: processo.value.data_final_validade,
                instituicaoExecutora: processo.value.instituicao_executora
            }
        }

        tipoOperacao.value = tipo;
        openModalOperacao.value = true;
    }

    return (
        <SttContainer>
            {
                !processo.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{strings.processoApac}</SttHeading>
                        <SttExpansionPanel
                            title={strings.dadosGerais}
                            children={
                                <>
                                    <SttTextItem key={uuidv4()} title={strings.situacao} content={
                                        <Chip className={classes.chip} style={{ backgroundColor: formatarSituacaoLabelCorProcesso().COR[processo.value.situacao] }} label={formatarSituacaoLabelCorProcesso().LABEL[processo.value.situacao]} />
                                    } />
                                    {processo.value.data_solicitacao_formatada && <SttTextItem key={uuidv4()} title={strings.dataSolicitacao} content={processo.value.data_solicitacao_formatada} />}
                                    {processo.value.sequencial_formatado && <SttTextItem key={uuidv4()} title={strings.sequencial} content={processo.value.sequencial_formatado} />}
                                    {processo.value.procedencia_processo && <SttTextItem key={uuidv4()} title={strings.procedenciaProcesso} content={DESCRICAO_PROCEDENCIA_PEDIDO(strings)[processo.value.procedencia_processo]} />}
                                    {processo.value.tipo && <SttTextItem key={uuidv4()} title={strings.tipoProcesso} content={DESCRICAO_TIPO_PROCESSO(strings)[processo.value.tipo]} />}
                                    {processo.value.complemento_procedencia && <SttTextItem key={uuidv4()} title={strings.complementoProcedencia} content={processo.value.complemento_procedencia} />}
                                    {processo.value.solicitante && <SttTextItem key={uuidv4()} title={strings.funcionarioSolicitante} content={processo.value.solicitante.nome} />}
                                    {processo.value.cidade_uf_solicitante && <SttTextItem key={uuidv4()} title={strings.cidadeSolicitante} content={processo.value.cidade_uf_solicitante} />}
                                    {processo.value.instituicao_solicitante && <SttTextItem key={uuidv4()} title={strings.instituicaoSolicitante} content={`${processo.value.instituicao_solicitante.nome} (CNES: ${processo.value.instituicao_solicitante.cnes})`} />}
                                    {processo.value.instituicao_autorizadora && <SttTextItem key={uuidv4()} title={strings.instituicaoAutorizadora} content={`${processo.value.instituicao_autorizadora.nome} (CNES: ${processo.value.instituicao_autorizadora.cnes})`} />}
                                    {processo.value.instituicao_executora && <SttTextItem key={uuidv4()} title={strings.instituicaoExecutora} content={`${processo.value.instituicao_executora.nome}  (CNES: ${processo.value.instituicao_executora.cnes})`} />}
                                    {processo.value.data_validade && <SttTextItem key={uuidv4()} title={strings.dataValidade} content={moment(processo.value.data_validade).format('DD/MM/YYYY')} />}
                                    {processo.value.data_final_validade && <SttTextItem key={uuidv4()} title={strings.dataFinalValidade} content={moment(processo.value.data_final_validade).format('DD/MM/YYYY')} />}
                                </>
                            }
                        />

                        {
                            processo.value.paciente &&
                            <SttExpansionPanel
                                title={strings.dadosPaciente}
                                children={
                                    <>
                                        {processo.value.paciente.nome && <SttTextItem key={uuidv4()} title={strings.nome} content={processo.value.paciente.nome} />}
                                        {processo.value.paciente.nome_mae && <SttTextItem key={uuidv4()} title={strings.nomeMae} content={processo.value.paciente.nome_mae} />}
                                        {processo.value.paciente.sexo && <SttTextItem key={uuidv4()} title={strings.genero} content={processo.value.paciente.sexo.descricao} />}
                                        {processo.value.paciente.data_nascimento && <SttTextItem key={uuidv4()} title={strings.dataNascimento} content={moment(processo.value.paciente.data_nascimento).format('DD/MM/YYYY')} />}
                                        {processo.value.paciente.cpf && <SttTextItem key={uuidv4()} title={strings.cpf} content={Util.util.adicionarMascara(('00000000000' + processo.value.paciente.cpf.replace(/[\. ,:-]+/g, "")).slice(-11), '000.000.000-00')} />}
                                        {processo.value.paciente.cartao_sus && <SttTextItem key={uuidv4()} title={strings.cartaoSus} content={processo.value.paciente.cartao_sus} />}
                                        {processo.value.paciente.endereco?.logradouro && <SttTextItem key={uuidv4()} title={strings.logradouro} content={processo.value.paciente.endereco.logradouro} />}
                                        {processo.value.paciente.endereco?.bairro && <SttTextItem key={uuidv4()} title={strings.bairro} content={processo.value.paciente.endereco.bairro} />}
                                        {processo.value.paciente.endereco?.cep && <SttTextItem key={uuidv4()} title={strings.cep} content={Util.util.adicionarMascara(processo.value.paciente.endereco.cep, '00000-000')} />}
                                        {processo.value.paciente.endereco?.cidade && <SttTextItem key={uuidv4()} title={strings.cidade} content={`${processo.value.paciente.endereco.cidade}/${processo.value.paciente.endereco.sigla_estado}`} />}
                                        {processo.value.paciente.endereco?.numero && <SttTextItem key={uuidv4()} title={strings.numero} content={processo.value.paciente.endereco.numero} />}
                                        {
                                            processo.value.paciente.contatos &&
                                            processo.value.paciente.contatos.map((contato) => {
                                                return (
                                                    <SttTextItem key={uuidv4()} title={contato.descricao_tipo_contato} content={contato.id_tipo_contato !== 4 ? Util.util.adicionarMascara(contato.contato, '(00) 90000-0000') : contato.contato} />
                                                )
                                            })
                                        }

                                    </>
                                }
                            />
                        }

                        {
                            processo.value.procedimento &&
                            <SttExpansionPanel
                                title={strings.dadosProcedimento}
                                children={
                                    <>
                                        {processo.value.procedimento?.nome && <SttTextItem key={uuidv4()} title={strings.nomeProcedimento} content={processo.value.procedimento.nome} />}
                                        {processo.value.procedimento?.codigo && <SttTextItem key={uuidv4()} title={strings.codigo} content={processo.value.procedimento.codigo} />}
                                        {processo.value.procedimento?.ano_mes_competencia && <SttTextItem key={uuidv4()} title={strings.anoMesProcedencia} content={Util.util.adicionarMascara(processo.value.procedimento.ano_mes_competencia, '0000/00')} />}
                                        {processo.value.procedimento?.quantidade && <SttTextItem key={uuidv4()} title={strings.quantidade} content={processo.value.procedimento.quantidade} />}
                                        {processo.value.data_solicitacao_laudo && <SttTextItem key={uuidv4()} title={strings.dataSolicitacaoLaudo} content={moment(processo.value.data_solicitacao_laudo).format('DD/MM/YYYY')} />}
                                    </>
                                }
                            />
                        }

                        {
                            processo.value.cid_10_principal &&
                            <SttExpansionPanel
                                title={strings.cid10Principal}
                                children={

                                    processo.value.cid_10_principal.tipo === 'categoria' ?
                                        <>
                                            {processo.value.cid_10_principal.codigo && <SttTextItem key={uuidv4()} title={strings.categoria} content={`${processo.value.cid_10_principal.codigo} - ${processo.value.cid_10_principal.nome}`} />}
                                        </> :
                                        <>
                                            {processo.value.cid_10_principal.codigo_categoria && <SttTextItem key={uuidv4()} title={strings.categoria} content={`${processo.value.cid_10_principal.codigo_categoria} - ${processo.value.cid_10_principal.nome_categoria}`} />}
                                            {processo.value.cid_10_principal.codigo && <SttTextItem key={uuidv4()} title={strings.subcategoria} content={`${processo.value.cid_10_principal.codigo} - ${processo.value.cid_10_principal.nome}`} />}
                                        </>
                                }
                            />
                        }

                        {
                            processo.value.cid_10_secundario &&
                            <SttExpansionPanel
                                title={strings.cid10Secundario}
                                children={

                                    processo.value.cid_10_secundario.tipo === 'categoria' ?
                                        <>
                                            {processo.value.cid_10_secundario.codigo && <SttTextItem key={uuidv4()} title={strings.categoria} content={`${processo.value.cid_10_secundario.codigo} - ${processo.value.cid_10_secundario.nome}`} />}
                                        </> :
                                        <>
                                            {processo.value.cid_10_secundario.codigo_categoria && <SttTextItem key={uuidv4()} title={strings.categoria} content={`${processo.value.cid_10_secundario.codigo_categoria} - ${processo.value.cid_10_secundario.nome_categoria}`} />}
                                            {processo.value.cid_10_secundario.codigo && <SttTextItem key={uuidv4()} title={strings.subcategoria} content={`${processo.value.cid_10_secundario.codigo} - ${processo.value.cid_10_secundario.nome}`} />}
                                        </>
                                }
                            />
                        }

                        {
                            (processo.value.resumo_exame_fisico ||
                                processo.value.exames_complementares ||
                                processo.value.justificativa) &&
                            <SttExpansionPanel
                                title={strings.justificativaSolicitacao}
                                children={
                                    <>
                                        {processo.value.resumo_exame_fisico && <SttTextItem key={uuidv4()} title={strings.resumoExameFisico} content={processo.value.resumo_exame_fisico} />}
                                        {processo.value.exames_complementares && <SttTextItem key={uuidv4()} title={strings.examesComplementares} content={processo.value.exames_complementares} />}
                                        {processo.value.justificativa && <SttTextItem key={uuidv4()} title={strings.justificativa} content={processo.value.justificativa} />}
                                    </>
                                }
                            />
                        }

                        {
                            processo.value.data_autorizacao &&
                            <SttExpansionPanel
                                title={strings.autorizacao}
                                children={
                                    <>
                                        {processo.value.numero_registro_sisreg && <SttTextItem key={uuidv4()} title={strings.numeroSolicitacaoSisreg} content={processo.value.numero_registro_sisreg} />}
                                        {processo.value.numero_autorizacao_apac && <SttTextItem key={uuidv4()} title={strings.numeroAutorizacaoControleAC} content={processo.value.numero_autorizacao_apac} />}
                                        {processo.value.data_geracao_numero_apac && <SttTextItem key={uuidv4()} title={strings.dataGeracaoNumeroApac} content={moment(processo.value.data_geracao_numero_apac).format('DD/MM/YYYY')} />}
                                        {processo.value.data_autorizacao && <SttTextItem key={uuidv4()} title={strings.dataAutorizacao} content={moment(processo.value.data_autorizacao).format('DD/MM/YYYY')} />}
                                        {processo.value.autorizador && <SttTextItem key={uuidv4()} title={strings.nomeAutorizador} content={processo.value.autorizador.nome} />}
                                    </>
                                }
                            />
                        }

                        {
                            processo.value.movimentacoes?.length > 0 &&
                            formatarSituacaoLabelCorProcesso().LABEL[processo.value.situacao] !== strings.emAnalise &&
                            <SttExpansionPanel
                                title={strings.movimentacoes}
                                children={
                                    processo.value.movimentacoes.map((movimentacao, index) => (
                                        <div key={index} className={classes.divWrapperMovimentacao}>
                                            {movimentacao.data_hora_formatada && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.data} content={movimentacao.data_hora_formatada} />}
                                            {movimentacao.nome_funcionario && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.responsavel} content={movimentacao.nome_funcionario} />}
                                            {movimentacao.conteudo && <SttTextItem classContent={classes.textoQuebraLinha} key={uuidv4()} title={strings.movimentacao} content={movimentacao.conteudo.replace(/<br>/g, '\n')} />}
                                            {
                                                (index + 1) != processo.value.movimentacoes.length &&
                                                <SttDivider />
                                            }
                                        </div>
                                    ))
                                }
                            />
                        }

                        {
                            processo.value.anexos?.length > 0 &&
                            <SttExpansionPanel
                                title={strings.anexos}
                                children={
                                    processo.value.anexos.map((anexo, index) => (
                                        <div key={index}>
                                            {
                                                anexo.nome &&
                                                <SttTextItem key={uuidv4()} title={`${strings.anexo} ${index + 1}`} content={
                                                    <SttLink
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={(e) => {
                                                            exibirGaleriaAnexos.value = true;
                                                            selectedItem.value = index;
                                                            e.preventDefault();
                                                        }}
                                                        target="_blank"
                                                    >
                                                        {anexo.nome}
                                                    </SttLink>
                                                } />
                                            }
                                            {anexo.tipo_anexo && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.tipoAnexo} content={anexo.tipo_anexo} />}
                                            {anexo.observacao && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.observacoes} content={anexo.observacao} />}

                                            {
                                                index !== (processo.value.anexos.length - 1) &&
                                                <SttDivider className={classes.divider} />
                                            }
                                        </div>
                                    ))
                                }
                            />
                        }

                        {
                            processo.value.situacao === situacao.CANCELADO &&
                            <SttExpansionPanel
                                title={strings.processoCancelado}
                                children={
                                    <div>
                                        {processo.value.motivo_cancelamento && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.motivo} content={processo.value.motivo_cancelamento} />}
                                        {processo.value.descricao_motivo_cancelamento && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.descricao} content={processo.value.descricao_motivo_cancelamento} />}
                                    </div>
                                }
                            />
                        }

                        {
                            processo.value.situacao === situacao.DEVOLVIDO &&
                            <SttExpansionPanel
                                title={strings.processoDevolvido}
                                children={
                                    <div>
                                        {processo.value.motivo_devolucao && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.motivo} content={processo.value.motivo_devolucao} />}
                                        {processo.value.descricao_motivo_devolucao && <SttTextItem classContent={classes.textItem} key={uuidv4()} title={strings.descricao} content={processo.value.descricao_motivo_devolucao} />}
                                    </div>
                                }
                            />
                        }

                        {
                            !esconderBotoes &&
                            <div className={classes.buttonWrapper}>
                                {
                                    (
                                        permissoesProcesso.value.podeEditar ||
                                        permissoesProcesso.value.podeEncaminhar
                                    ) &&
                                    !temPerfilRBAC(usuario, PERFIL.APAC_AUTORIZADOR) &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => {
                                            navigate('/solicitacao', { state: { idSolicitacao: processo.value.id } });
                                        }}
                                    >
                                        {strings.alterar}
                                    </SttButton>
                                }

                                {
                                    permissoesProcesso.value.podeDevolver &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => { abrirOperacao(TIPO_OPERACAO.DEVOLUCAO) }}
                                    >
                                        {strings.devolver}
                                    </SttButton>
                                }

                                {
                                    permissoesProcesso.value.podeCancelar &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => { abrirOperacao(TIPO_OPERACAO.CANCELAMENTO) }}
                                    >
                                        {strings.cancelar}
                                    </SttButton>
                                }

                                {
                                    permissoesProcesso.value.podeAutorizar &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => { abrirOperacao(TIPO_OPERACAO.AUTORIZACAO) }}
                                    >
                                        {strings.autorizar}
                                    </SttButton>
                                }

                                {
                                    permissoesProcesso.value.podeAprovar &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => { abrirOperacao(TIPO_OPERACAO.APROVACAO) }}
                                    >
                                        {
                                            processo.value.data_validade ?
                                                strings.alterarDataValidade :
                                                strings.aprovar
                                        }
                                    </SttButton>
                                }

                                {
                                    processo.value.situacao === situacao.AUTORIZADO &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => {
                                            if (permissoesProcesso.value.podeSolicitarNumeroApac && !processo.value.numero_autorizacao_apac) {
                                                solicitarNumeroApac(true);
                                                return;
                                            }

                                            imprimirProcesso(processo.value.id);
                                        }}
                                    >
                                        {strings.imprimir}
                                    </SttButton>
                                }

                                {
                                    permissoesProcesso.value.podeEncaminharAutorizador &&
                                    processo.value.outro_procedimento &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => { abrirOperacao(TIPO_OPERACAO.ENCAMINHAR_AUTORIZADOR) }}
                                    >
                                        {
                                            processo.value.autorizador ?
                                                strings.alterarAutorizador :
                                                strings.encaminharAutorizador
                                        }
                                    </SttButton>
                                }

                                {
                                    permissoesProcesso.value.podeInvalidar &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => { abrirOperacao(TIPO_OPERACAO.INVALIDACAO) }}
                                    >
                                        {strings.invalidar}
                                    </SttButton>
                                }

                                {
                                    permissoesProcesso.value.podeSolicitarNumeroApac && !processo.value.numero_autorizacao_apac &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        onClick={() => {
                                            confirmarSolicitarNumeroApac();
                                        }}
                                    >
                                        {strings.solicitarNumeroApac}
                                    </SttButton>
                                }
                            </div>
                        }

                        <Operacoes
                            tipoOperacao={tipoOperacao}
                            open={openModalOperacao}
                            id={processo.value.id}
                            voltarPesquisa={voltar}
                            outrosDados={outrosDados}
                        />

                        <SttModal
                            title={strings.protocoloClinico}
                            open={exibirGaleriaAnexos.value}
                            outModalClose={() => exibirGaleriaAnexos.value = false}
                            iconClose={() => exibirGaleriaAnexos.value = false}
                            maxWidth="xl"
                            fullWidth={true}
                            PaperProps={{
                                style: {
                                    height: '80%'
                                },
                            }}
                            children={
                                <Carousel
                                    showThumbs={false}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={processo.value.anexos.length > 1 && isDesktop}
                                    className={classes.carousel}
                                    selectedItem={selectedItem.value}
                                    showArrows={isDesktop}
                                >
                                    {
                                        processo.value.anexos.map((a, index) => (
                                            <div className={classes.divCentral} key={index}>
                                                <AnexosProcesso anexo={{
                                                    url: `${APAC_API_BASE_URL}/anexo/${a.idCodificado}/${encodeURIComponent(a.nome)}`,
                                                    observacao: a.observacao
                                                }} />
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            }
                            footer={
                                <div>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => exibirGaleriaAnexos.value = false}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </div>
                            }
                        />

                    </>
            }
        </SttContainer>
    )

}

export default memo(Detalhes);