export const FIELDS = {
    PROCEDIMENTO: 'procedimento',
    OUTRO_PROCEDIMENTO: 'outroProcedimento',
    INSTITUICAO_SOLICITANTE: 'instituicaoSolicitante',
    INSTITUICAO_AUTORIZADORA: 'instituicaoAutorizadora',
    INSTITUICAO_EXECUTORA: 'instituicaoExecutora',
    QUANTIDADE: 'quantidade',
    PROCEDENCIA: 'procedencia',
    COMPLEMENTO_PROCEDENCIA: 'complementoProcedencia',
    TIPO_SOLICITACAO: 'tipoSolicitacao',
    OUTRO_PROCEDIMENTO: 'outroProcedimento',
    NOVO_PROCEDIMENTO: 'novoProcedimento',
    DATA_SOLICITACAO_LAUDO: 'dataSolicitacaoLaudo',
    SOLICITANTE: 'solicitante'
}